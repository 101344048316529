var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"width":"100%"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-card-title',{staticClass:"float-left pa-0"},[_c('v-app-bar',{staticClass:"white pa-0",attrs:{"flat":"","elevate-on-scroll":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('alert_case.title'))+" ")]),_c('v-spacer')],1)],1),_c('v-data-table',{staticClass:"elevation-1 col-12",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.last_hit_at",fn:function(ref){
var item = ref.item;
return [_c('DateFormat',{attrs:{"date":item.last_hit_at,"showtime":true}})]}},{key:"item.client_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.person || item.company || { id: 'Deleted' }).id)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.last_screening_inputs || { name: 'N/A' }).name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('alert_case.table.header.status.options')[item.status])+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{ name: 'AlertCaseDetails', params: { case_id: item.id } },"data-cy":"to_AlertCaseDetails_btn"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }