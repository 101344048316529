<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-container
      fill-height
      fluid
    >
      <v-card-title class="float-left pa-0">
        <v-app-bar
          flat
          class="white pa-0"
          elevate-on-scroll
          dense
        >
          <v-toolbar-title>
            {{ $t('alert_case.title') }}
          </v-toolbar-title>
          <v-spacer />
        </v-app-bar>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1 col-12"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:[`item.last_hit_at`]="{ item }">
          <DateFormat
            :date="item.last_hit_at"
            :showtime="true"
          />
        </template>
        <template v-slot:[`item.client_id`]="{ item }">
          {{ (item.person || item.company || { id: 'Deleted' }).id }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ (item.last_screening_inputs || { name: 'N/A' }).name }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ $t('alert_case.table.header.status.options')[item.status] }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            icon
            :to="{ name: 'AlertCaseDetails', params: { case_id: item.id } }"
            data-cy="to_AlertCaseDetails_btn"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import DateFormat from '@/components/common/DateFormat'

export default {
  components: {
    DateFormat
  },
  computed: {
    headers () {
      return [
        { text: this.$t('alert_case.table.header.id.label'), align: 'start', value: 'id' },
        { text: this.$t('alert_case.table.header.last_hit_at.label'), value: 'last_hit_at' },
        { text: this.$t('alert_case.table.header.client_id.label'), sortable: false, value: 'client_id' },
        { text: this.$t('alert_case.table.header.name.label'), sortable: false, value: 'name' },
        { text: this.$t('alert_case.table.header.status.label'), value: 'status' },
        { text: this.$t('alert_case.table.header.cnt_pending.label'), sortable: false, value: 'cnt_pending' },
        { text: this.$t('listing.table.action.label'), sortable: false, value: 'action', width: '80px' }
      ]
    },
    options: {
      get () {
        return this.$store.state.alert_case.pool.options
      },
      set (_options) {
        this.$store.commit('alert_case/SET_POOL_OPTIONS', _options)
      }
    }
  },
  data () {
    return {
      totalItems: 0,
      items: [],
      loading: true
      // options: {}
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getAlertCasePool: 'alert_case/findWhere',
      countAlertCasePool: 'alert_case/count'
    }),
    getDataFromApi () {
      this.loading = true
      Promise.all([
        this.getAlertCasePool({
          populate: 'company,person',
          limit: this.options.itemsPerPage,
          skip: (parseInt(this.options.page) - 1) * this.options.itemsPerPage,
          sort: this.options.sortBy.map((sortBy, key) => {
            // if (sortBy === 'client_id') {
            //   return [
            //     `company ${this.options.sortDesc[key] ? 'desc' : 'asc'}`,
            //     `person ${this.options.sortDesc[key] ? 'desc' : 'asc'}`
            //   ]
            // }
            return `${sortBy} ${this.options.sortDesc[key] ? 'desc' : 'asc'}`
          })
          // sort: this.options.sortBy.reduce((sortArray, sortBy, index) => {
          //   if (sortBy === 'client_id') {
          //     sortArray.push({ company: this.options.sortDesc[index] ? 'DESC' : 'ASC' })
          //     sortArray.push({ person: this.options.sortDesc[index] ? 'DESC' : 'ASC' })
          //   } else {
          //     sortArray.push({ [sortBy]: this.options.sortDesc[index] ? 'DESC' : 'ASC' })
          //   }
          //   return sortArray
          // }, [])
        }),
        this.countAlertCasePool()
      ])
        .then(([result, resultCnt]) => {
          this.items = result.data
          this.totalItems = resultCnt.data.count
          this.loading = false
        })
    }
  }
}
</script>

<style>

</style>
